<template>
    <div>
      <div class="row">
        <div class="col-md-12">
          <router-link to="/support/bulk-messages" class="float-right mb-2 btn btn-primary">
            <i class="fas fa-envelope"></i> Send new bulk messages
          </router-link>
        </div>
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <side-panel @campaignSelected="campaignSelected" />
              <div class="inbox-rightbar">

                <template v-if="campaign">
                  <div class="mt-0">
                    <h5 class="font-18">
                      <span class="mr-3">{{campaign['createdAt']}}</span>
                      <span class="font-12 badge badge-dark p-1 ">{{campaign['status']}}</span>
                    </h5>
                  </div>

                  <!--        Right side panel shows here -->
                  <div class="row">
                    <div class="col-md-12">
                      <table class="table">
                        <tr>
                          <td>enabled push notification </td>
                          <td class="text-right"> {{campaign['use_push'] ? 'YES' : 'NO'}} </td>
                        </tr>

                        <tr>
                          <td>enabled sms </td>
                          <td class="text-right"> {{campaign['use_sms'] ? 'YES' : 'NO'}} </td>
                        </tr>

                      </table>

                      <div class="row">
                        <div class="col-md-12">
                          <b-card>
                            {{campaign['message']}}
                          </b-card>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Selected drivers ({{campaign['complaints'].length}})</label>
                            <b-card>
                              <b-card-text>
                                <template  v-for="complaint in campaign['complaints']">
                                  <a style="cursor: pointer">
                                    <span class="badge badge-pill badge-soft-primary p-2 mr-2"> {{complaint['driver']['name']}} {{complaint['driver']['other_names']}}  </span>
                                  </a>
                                </template>

                              </b-card-text>

                            </b-card>

                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </template>

              </div >

            </div>
            <!-- card -->
          </div>
          <!-- end Col-9 -->
        </div>
      </div>
    </div>
</template>

<script>
import SidePanel from "./sidepanel";

export default {
  name: "bulk-messages-history",
  components: {
    SidePanel
  },
  data() {
    return {
      campaign: null
    }
  },
  methods: {
    campaignSelected(campaign) {
      this.campaign = campaign
      console.log('selected campaign', campaign)
    }
  },
  created() {
    this.$store.dispatch('setPageTitle', 'Bulk messages history')

  }
}
</script>

<style scoped>

</style>