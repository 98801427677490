<template>
<div class="inbox-leftbar">
<!--    <b-button block variant="danger" @click="showModal = true">Compose</b-button>-->
<!--    &lt;!&ndash; start search box &ndash;&gt;-->
<!--    <form class="search-bar">-->
<!--      <div class="position-relative">-->
<!--        <input-->
<!--            type="text"-->
<!--            class="form-control form-control-light"-->
<!--            placeholder="Search Driver ..."-->
<!--            @keyup="onSearch"-->
<!--        />-->
<!--        <span class="mdi mdi-magnify"></span>-->
<!--      </div>-->
<!--    </form>-->
<!--    &lt;!&ndash; end search box &ndash;&gt;-->

    <div class="mail-list mt-4">

      <template v-for="(campaign, index) in campaigns">
        <div class="media mb-4" style="cursor:pointer;" :class="{'active': index === activeIndex}"  @click="campaignSelected(campaign, index)">
          <div class="media-body">
            <h5 class="mt-0 mb-0 ml-2 font-14">
             {{campaign['createdAt']}}
            </h5>
            <p class="mt-1 mb-0 ml-2 text-muted font-14">
              <span class="w-75">status: {{campaign['status']}}</span>
            </p>
          </div>
        </div>
      </template>

    </div>


</div>
</template>


<script>

import { Skeleton } from 'vue-loading-skeleton';
import {notificationsServices} from "../../../../apis/notifications.services";


/**
 * Side-panel component
 */
export default {

  components:{
    Skeleton
  },
  data() {
    return {
      activeIndex: 0,
      campaigns: []
    };
  },

  methods: {

    campaignSelected(campaign, index){
      this.activeIndex = index;
      this.$emit('campaignSelected', campaign)
    },

    fetchCampaigns(){
      this.$store.dispatch('showLoader')
      notificationsServices.getLastNWeeksCampaign().then((data) => {
        this.$store.dispatch('hideLoader')

        if(!data.status){
          this.$store.dispatch('error', {message: data.message, showSwal: true})
          return;
        }

        this.campaigns = data['extra']

        console.log('campaigns', this.campaigns)
        const campaign = this.campaigns[this.activeIndex]
        this.campaignSelected(campaign, this.activeIndex)

      })
    }

  },
  created() {
    this.fetchCampaigns()
  },
};
</script>

<style scoped>
  .active {
    background-color: #f3f7f9;
    border-color: #f3f7f9;
    padding: 10px;
    border-radius: 5px;
  }
</style>